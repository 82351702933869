import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ResourcePicklistFilterQuery } from '../ResourcePicklistFilter';
import { ColumnConfig } from '../../../../models/TableView';
import { GenericFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';
import { useTableView } from '../../../../contexts/table-view/TableViewContext';

const ResourcePicklistFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter, value } = props;
  const { t } = useTranslation(['table-view', 'common']);
  const { selectedTemplateFormId } = useTableView();

  const answerFilters = filter as GenericFilterValue | undefined;

  const { data } = useQuery(ResourcePicklistFilterQuery, {
    variables: {
      tfId: selectedTemplateFormId,
    },
  });
  const subtitles = useMemo(() => {
    const result: Record<string, string> = {};

    for (const ref of data?.clientFormsReferences ?? []) {
      result[ref!.id as string] = ref!.displayTitle as string;
    }

    return result;
  }, [data?.clientFormsReferences]);

  return (
    <div className="flex flex-wrap items-center gap-2">
      {answerFilters?.map((answer, i) => (
        <div key={`${value}-answer-${i}`}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={answer === false ? t('filters.no-data') : subtitles[answer as string]} />
        </div>
      ))}
    </div>
  );
};

export default ResourcePicklistFilterOverview;
